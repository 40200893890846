import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // redirect: '/home',
    name: 'Home',
    component: () => import('../views/home.vue'),
    meta: { title: '首页' },
  },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: () => import('../views/home.vue'),
  //   meta: { title: '首页' },
  // }

]

const router = new VueRouter({
  routes,
  mode: 'history', // 去掉url中的#
})
router.afterEach((to) => {
  window.scrollTo(0, 0);
  /**
   * 判断路由元信息
   * 动态设置页面标题
   */
  if (to.matched.some((record) => record.meta.title)) {
    document.setTitle(to.meta.title);
  }
});

/**
 * 动态设置页面标题具体实现
 */
document.setTitle = function (title) {
  document.title = title;
};

export default router
