import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import 'amfe-flexible';
import 'lib-flexible'

import "./plugins/index";


Vue.config.productionTip = false

// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// if (process.env.NODE_ENV !== 'production'){
//   Vue.use(vConsole)
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
